import React from 'react';

const NotRinseBorder = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <defs>
      <clipPath>
        <rect width="28.422" height="39" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-183 -1697)">
      <g transform="translate(-628 1426)">
        <g transform="translate(811 271)" fill="none" stroke="currentColor" strokeWidth="1">
          <rect width="70" height="70" stroke="none" />
          <rect x="0.5" y="0.5" width="69" height="69" fill="none" />
        </g>
      </g>
      <g transform="translate(203.789 1712.5)">
        <g clipPath="url(#clip-path)">
          <path
            d="M0,24.717A23.825,23.825,0,0,1,2.18,15.69,45.407,45.407,0,0,1,10.307,3.626C11.3,2.53,12.364,1.5,13.415.452c.61-.606,1.017-.6,1.636-.007a50.288,50.288,0,0,1,10.7,14.071,25.23,25.23,0,0,1,2.585,8.528A14.187,14.187,0,0,1,.276,27.23C.137,26.4.09,25.555,0,24.717M14.262,2.108c-.142.132-.245.222-.341.318A55.253,55.253,0,0,0,7.605,9.849,33.565,33.565,0,0,0,2.638,19.6a16.172,16.172,0,0,0-.671,7.052,12.415,12.415,0,0,0,24.6-3.319,23.5,23.5,0,0,0-2.391-7.94A46.853,46.853,0,0,0,16.5,4.459c-.725-.794-1.483-1.559-2.241-2.351"
            transform="translate(0 0)"
            fill="currentColor"
          />
          <path
            d="M12.2,82.233c0-.041,0-.083,0-.124-.011-.674.3-1.085.844-1.113s.892.366.924,1.037a8.853,8.853,0,0,0,4.324,7.456.9.9,0,1,1-.958,1.492A10.641,10.641,0,0,1,12.2,82.233"
            transform="translate(-8.593 -57.056)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default NotRinseBorder;
